<template>
  <!--考勤统计-> 按工地统计 -->
  <!-- style="padding-top: 60px" -->
  <div>
    <s-search
      :queryItems="queryItems"
      @fetchData="fetchUserAttend"
      ref="SSearch"
      @mode="GetMode"
      @getDatesStart="getDatesStart"
      @getDatesEnd="getDatesEnd"
    ></s-search>
    <div class="table_top">
      <div class="table-button">
        <a-button
          type="plain"
          icon="cloud-download"
          @click="handleExport"
          v-if="
            isShowBtns.indexOf('AttendStatistics-/api/count/acountwsexport') >
            -1
          "
          >导出</a-button
        >
        <!--  -->
        <a-button
          type="plain"
          icon="cloud-download"
          @click="$refs.table.refresh(true)"
          >刷新</a-button
        >
        <!-- <columns-set
          tableName="OrganizationalStatistics"
          :columns="columns"
          @changeColumns="changeColumns"
        ></columns-set> -->
      </div>
    </div>
    <s-table
      ref="table"
      size="default"
      :columns="tableColumns"
      rowKey="serial"
      :data="loadData"
      :showPagination="true"
      :scroll="{ x: 1200, y: 'calc(82vh - 150px )' }"
    >
      <span slot="serial" slot-scope="text, record, index">
        {{ (pageNo - 1) * pageSize + index + 1 }}
      </span>
      <span slot="ws_name" slot-scope="text" class="ws_name">
        <a-tooltip placement="topLeft">
          <template slot="title">
            <span>{{ text }}</span>
          </template>
          <span style="cursor: pointer">{{
            text.replace(/直辖市|-北京|-天津/g, "")
          }}</span>
        </a-tooltip>
      </span>
      <span slot="打卡人数" class="TitleFlex"
        ><div>打卡人数</div>
        <div class="Title_icon">
          <p
            class="Title_up"
            @click="sortColumn(1, 2, 'ws_a_worksumBlue')"
            :class="ws_a_worksumBlue == true ? 'BlueBlock' : ''"
          >
            <a-icon type="caret-up" />
          </p>
          <p
            class="Title_down"
            @click="sortColumn(1, 1, 'ws_a_worksumDownBlue')"
            :class="ws_a_worksumDownBlue == true ? 'BlueBlock' : ''"
          >
            <a-icon type="caret-down" />
          </p>
        </div>
      </span>
      <span slot="上班打卡次数" class="TitleFlex"
        ><div>上班打卡次数</div>
        <div class="Title_icon">
          <p
            class="Title_up"
            @click="sortColumn(2, 2, 'ws_a_onworknumBlue')"
            :class="ws_a_onworknumBlue == true ? 'BlueBlock' : ''"
          >
            <a-icon type="caret-up" />
          </p>
          <p
            class="Title_down"
            @click="sortColumn(2, 1, 'ws_a_onworknumDownBlue')"
            :class="ws_a_onworknumDownBlue == true ? 'BlueBlock' : ''"
          >
            <a-icon type="caret-down" />
          </p>
        </div>
      </span>
      <span slot="下班打卡次数" class="TitleFlex"
        ><div>下班打卡次数</div>
        <div class="Title_icon">
          <p
            class="Title_up"
            @click="sortColumn(3, 2, 'ws_a_offworknumBlue')"
            :class="ws_a_offworknumBlue == true ? 'BlueBlock' : ''"
          >
            <a-icon type="caret-up" />
          </p>
          <p
            class="Title_down"
            @click="sortColumn(3, 1, 'ws_a_offworknumDownBlue')"
            :class="ws_a_offworknumDownBlue == true ? 'BlueBlock' : ''"
          >
            <a-icon type="caret-down" />
          </p>
        </div>
      </span>
      <span slot="未下班打卡次数" class="TitleFlex"
        ><div>未下班打卡次数</div>
        <div class="Title_icon">
          <p
            class="Title_up"
            @click="sortColumn(4, 2, 'ws_a_unoffworknumBlue')"
            :class="ws_a_unoffworknumBlue == true ? 'BlueBlock' : ''"
          >
            <a-icon type="caret-up" />
          </p>
          <p
            class="Title_down"
            @click="sortColumn(4, 1, 'ws_a_unoffworknumDownBlue')"
            :class="ws_a_unoffworknumDownBlue == true ? 'BlueBlock' : ''"
          >
            <a-icon type="caret-down" />
          </p>
        </div>
      </span>
      <span slot="ws_a_onworknum" slot-scope="text, record" class="Blue">
        <span @click="goToWorkerAttend(record.ws_name, '')"> {{ text }}</span>
      </span>
      <span slot="ws_a_offworknum" slot-scope="text, record" class="Blue">
        <span @click="goToWorkerAttend(record.ws_name, '1')"> {{ text }}</span>
      </span>
      <span slot="ws_a_unoffworknum" slot-scope="text, record" class="Blue">
        <span @click="goToWorkerAttend(record.ws_name, '2')"> {{ text }}</span>
      </span>
    </s-table>
    <div class="table_buttom_info">
      *按工地统计：根据查询条件，查看工地出勤人员打卡次数统计
    </div>
    <a-modal
      class="tips-modal"
      title="提示"
      okText="确定"
      cancelText="取消"
      :visible="visibleExport"
      :confirm-loading="confirmExportLoading"
      @ok="exportOk"
      @cancel="exportCancel"
    >
      <p>确定导出？</p>
    </a-modal>
  </div>
</template>

<script>
import { SSearch, STable, ColumnsSet } from "@/components";
import { getAcountws } from "@/api/dataStatistics.js";
import { ExportAcountws } from "@/api/export";
import moment from "moment";
// 搜索条件
const queryItems = [
  { label: "工地名称", value: "ws_name", type: "input" },
  // 分公司名称
  {
    label: "分公司名称",
    value: "bc_id",
    type: "select",
    selectOption: "CompanyList",
    defaultValue: "",
  },
  { label: "合同编号", value: "ws_only", type: "input" },
  {
    label: "项目经理",
    value: "w_jl_name",
    type: "input",
  },
  {
    label: "工程管家",
    value: "w_gj_name",
    type: "input",
  },
  {
    label: "考勤日期查询",
    value: "w_time",
    type: "dateRange",
    range: ["w_time_begin", "w_time_end"],
    keyType: "WorkerAttend",
    defaultValue: [],
  },
];
const columns = [
  {
    title: "序号",
    scopedSlots: { customRender: "serial" },
    width: "70px",
    fixed: "left",
    dataIndex: "serial",
    align: "center",
  },
  {
    title: "工地名称",
    width: "200px",
    dataIndex: "ws_name",
    scopedSlots: { customRender: "ws_name" },
    ellipsis: true,
  },
  {
    title: "分公司",
    dataIndex: "bc_name",
    align: "center",
  },

  {
    title: "项目经理",
    dataIndex: "w_jl_name",
    // sorter: (a, b) => a.age - b.age,
    // scopedSlots: { customRender: "name" },
    align: "center",
  },
  {
    title: "工程管家",
    dataIndex: "w_gj_name",
    // scopedSlots: { customRender: "cs_bossname" },
    // slots: { title: "deviceBindTotal" },
    align: "center",
  },
  {
    // title: "打卡人数",
    dataIndex: "ws_a_worksum",
    slots: { title: "打卡人数" },
    scopedSlots: { customRender: "ws_a_worksum" },
    align: "center",
  },
  {
    // title: "上班打卡次数",
    dataIndex: "ws_a_onworknum",
    slots: { title: "上班打卡次数" },
    scopedSlots: { customRender: "ws_a_onworknum" },
    align: "center",
  },
  {
    // title: "下班打卡次数",
    dataIndex: "ws_a_offworknum",
    slots: { title: "下班打卡次数" },
    scopedSlots: { customRender: "ws_a_offworknum" },
    align: "center",
  },
  {
    // title: "未下班打卡次数",
    dataIndex: "ws_a_unoffworknum",
    slots: { title: "未下班打卡次数" },
    scopedSlots: { customRender: "ws_a_unoffworknum" },
    align: "center",
  },
];

const orderParam = [
  "bc_id",
  "ws_name",
  "ws_only",
  "w_jl_name",
  "w_gj_name",
  "w_time_begin",
  "w_time_end",
  "sort_column",
  "sort_rule",
  "pageNo",
  "pageSize",
];
export default {
  name: "People",
  components: {
    SSearch,
    STable,
    ColumnsSet,
  },
  data() {
    this.queryItems = queryItems;
    this.columns = columns;
    return {
      tableColumns: [],
      // 查询参数
      queryParam: {},
      // 导出弹出框
      visibleExport: false,
      confirmExportLoading: false,
      //当前页数，当前条数
      pageNo: 1,
      pageSize: 10,
      ws_a_worksumBlue: false,
      ws_a_worksumDownBlue: false,
      ws_a_onworknumBlue: false,
      ws_a_onworknumDownBlue: false,
      ws_a_offworknumBlue: false,
      ws_a_offworknumDownBlue: false,
      ws_a_unoffworknumBlue: false,
      ws_a_unoffworknumDownBlue: false,
      start_time: "",
      end_time: "",
      datepickerType: "date",
      isShowBtns: [],
    };
  },
  created() {
    this.tableColumns = columns;
    // this.changeColumns();
    let yestoday =
      new Date(new Date().setHours(0, 0, 0, 0)).getTime() - 1000 * 3600 * 24;
    this.queryItems[5].defaultValue = [moment(yestoday), moment(yestoday)];
    this.queryParam.w_time_begin = yestoday / 1000;
    this.queryParam.w_time_end = yestoday / 1000;
    this.start_time = yestoday / 1000;
    this.end_time = yestoday / 1000;
  },
  watch: {},
  mounted() {
    this.isShowBtns = sessionStorage.getItem("isShowBtns").split(",");
  },
  computed: {},
  methods: {
    // 自定义表头列
    // changeColumns() {
    //   let ColumnsSet = JSON.parse(
    //     localStorage.getItem("OrganizationalStatisticsColumnsSet")
    //   );
    //   let value = ColumnsSet ? ColumnsSet : [];
    //   let selectColumns = columns.filter((el) => value.includes(el.dataIndex));
    //   this.tableColumns = selectColumns.length ? selectColumns : columns;
    // },
    // 加载数据方法 必须为 Promise 对象
    loadData(parameter) {
      this.pageNo = parameter.pageNo;
      this.pageSize = parameter.pageSize;
      const param = Object.assign({}, parameter, this.queryParam);
      const requestParameters = this.$order(param, orderParam);
      const params = {
        ...requestParameters,
        verify: this.$verify(requestParameters, orderParam),
      };
      return getAcountws(params).then((res) => {
        // this.data = res.data.data;
        // let data = res.data;
        res.data.pageNo = res.data.page;
        res.data.totalCount = res.data.sum;
        return res.data;
      });
    },

    // 导出
    handleExport() {
      this.visibleExport = true;
    },
    exportOk() {
      console.log("ok");
      this.confirmExportLoading = true;
      const params = {
        bc_id: this.queryParam.bc_id,
        ws_name: this.queryParam.ws_name,
        ws_only: this.queryParam.ws_only,
        w_jl_name: this.queryParam.w_jl_name,
        w_gj_name: this.queryParam.w_gj_name,
        w_time_begin: this.queryParam.w_time_begin,
        w_time_end: this.queryParam.w_time_end,
        sort_column: this.queryParam.sort_column,
        sort_rule: this.queryParam.sort_rule,
      };
      ExportAcountws(params).then((res) => {
        var blob = res;
        var reader = new FileReader();
        reader.readAsDataURL(blob); // 转换为base64，可以直接放入a标签href
        reader.onload = function (e) {
          // 转换完成，创建一个a标签用于下载
          var a = document.createElement("a");
          a.download = "考勤统计-按工地统计.xls";
          a.href = e.target.result;
          // $("body").append(a);    // 修复firefox中无法触发click
          a.click();
          a.remove();
        };

        this.visibleExport = false;
        this.confirmExportLoading = false;
      });
    },

    exportCancel() {
      this.visibleExport = false;
    },

    //查询触发
    fetchUserAttend(value) {
      this.queryParam = value;
      this.$refs.table.refresh(true);
    },
    // 排序
    sortColumn(sort_column, sort_rule, type) {
      console.log(sort_column, sort_rule, type);
      this.queryParam.sort_column = sort_column;
      this.queryParam.sort_rule = sort_rule;
      // this.loadData();
      this.$refs.table.refresh(true);

      if (type) {
        // this[type+'Blue']=true
        this.ws_a_worksumBlue = false;
        this.ws_a_worksumDownBlue = false;
        this.ws_a_onworknumBlue = false;
        this.ws_a_onworknumDownBlue = false;
        this.ws_a_offworknumBlue = false;
        this.ws_a_offworknumDownBlue = false;
        this.ws_a_unoffworknumBlue = false;
        this.ws_a_unoffworknumDownBlue = false;
        this[type] = true;
        console.log(type);
      }
    },
    GetMode(value) {
      console.log(value);
      this.datepickerType = value;
    },
    // 跳转到人员考勤页
    //  参数依次为 工地名称  考勤类型
    goToWorkerAttend(ws_name, attendance_type) {
      // localStorage.setItem("path", "WorkerAttend");
      // const { href } = this.$router.resolve({
      //   path: "/WorkerAttend",
      //   query: {
      //     start_time: this.start_time,
      //     end_time: this.end_time,
      //     ws_name: ws_name,
      //     attendance_type: attendance_type,
      //   },
      // });
      // window.open(href, "_blank");
      this.$store.commit("SET_CURRENT", "WorkerAttend");
      this.$router.push({
        path: "/WorkerAttend",
        query: {
          start_time: this.start_time,
          end_time: this.end_time,
          ws_name: ws_name,
          attendance_type: attendance_type,
        },
      });
    },
    getDatesStart(dates) {
      // console.log(dates);
      if (this.datepickerType == "week") {
        this.start_time = this.$beginOfWeek(dates * 1000);
      } else if (this.datepickerType == "month") {
        this.start_time = this.$beginOfMonth(dates * 1000);
      } else {
        this.start_time = dates;
      }
    },
    getDatesEnd(dates) {
      // console.log(dates);
      if (this.datepickerType == "week") {
        this.end_time = this.$endOfWeek(dates * 1000);
      } else if (this.datepickerType == "month") {
        this.end_time = this.$endOfMonth(dates * 1000);
      } else {
        this.end_time = dates;
      }
    },
  },
};
</script>
<style lang="less" scoped>
// 表头居中显示
/deep/.ant-table-thead > tr > th {
  text-align: center;
}
.table_top {
  display: flex;
  justify-content: right;
  .table_info {
    color: gray;
  }
}
.table_buttom_info {
  text-align: left;
  color: gray;
}
.TitleFlex {
  display: flex;
  // line-height: 100%;
  align-items: center;
  .Title_icon {
    margin-left: 10px;
    // margin-top: -10px;
    padding-top: 10px;
  }
  .Title_up {
    // margin-bottom: -15px;
    margin-bottom: 0%;
    // background-color: pink;
    width: 15px;
    height: 10px;
    line-height: 10px;
  }
  .Title_down {
    // margin-top: -15px;
    width: 15px;
    height: 10px;
    line-height: 10px;
    // background-color: blue;
    // :hover {
    //   // color: aqua;
    // }
  }
  .BlueBlock {
    color: rgb(3, 104, 255);
  }
}
.Blue {
  color: rgb(3, 104, 255);
  cursor: pointer;
}
</style>
