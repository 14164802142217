<template>
  <!-- 考勤统计 -->
  <div class="all">
    <div class="buttons">
      <a-button
        class="btn"
        @click="showStatistics('People')"
        :type="showType === 'People' ? 'primary' : ''"
        >按人员统计</a-button
      >
      <a-button
        class="btn"
        @click="showStatistics('Site')"
        :type="showType === 'Site' ? 'primary' : ''"
        >按工地统计</a-button
      >
    </div>

    <div class="statistics">
      <!-- 按主要人员统计 -->
      <div v-if="showType === 'People'">
        <People />
      </div>
      <!-- 按工地统计 -->
      <div v-if="showType === 'Site'">
        <Site />
      </div>
    </div>
  </div>
</template>

<script>
import People from "./People.vue";
import Site from "./Site.vue";
export default {
  name: "AttendStatistics",
  components: { People, Site },
  data() {
    return {
      showType: "People",
    };
  },
  created() {},
  watch: {},
  computed: {},
  methods: {
    showStatistics(type) {
      console.log(type);
      this.showType = type;
    },
  },
};
</script>
<style lang="less" scoped>
.all {
  padding-top: 5px;
  width: 100%;
  height: 100%;
  .buttons {
    text-align: left;
    width: 500px;
    //   background-color: pink;
    .btn {
      width: 120px;
      height: 40px;
      // border: 1px solid #40a9ff;
      border-radius: 0;
    }
  }

  .statistics {
    // background-color: pink;
    width: 100%;
    // height: calc(82vh - 150px);
    height: 85%;
  }
}
</style>
