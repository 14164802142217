<template>
  <!--考勤统计-> 按人员统计 -->
  <!-- style="padding-top: 60px" -->
  <div>
    <s-search
      :queryItems="queryItems"
      @fetchData="fetchUserAttend"
      ref="SSearch"
      @mode="GetMode"
      @getDatesStart="getDatesStart"
      @getDatesEnd="getDatesEnd"
    ></s-search>
    <div class="table_top">
      <div class="table_info">
        <!-- 统计截止时间：2023/05/05 00:00:-2023/06/06 00:00 -->
        {{ table_info }}
        <!-- 统计截止时间： -->
        <!-- {{ $Format(show_start_time, "date") }}-{{
          $Format(show_end_time, "date")
        }} -->
      </div>
      <div class="table-button">
        <a-button
          type="plain"
          icon="cloud-download"
          @click="handleExport"
          v-if="
            isShowBtns.indexOf(
              'AttendStatistics-/api/count/acountworkerexport'
            ) > -1
          "
          >导出</a-button
        >
        <!--  -->
        <a-button
          type="plain"
          icon="cloud-download"
          @click="$refs.table.refresh(true)"
          >刷新</a-button
        >
        <!-- <columns-set
          tableName="OrganizationalStatistics"
          :columns="columns"
          @changeColumns="changeColumns"
        ></columns-set> -->
      </div>
    </div>
    <s-table
      ref="table"
      size="default"
      :columns="tableColumns"
      rowKey="serial"
      :data="loadData"
      :showPagination="true"
    >
      <span slot="serial" slot-scope="text, record, index">
        {{ (pageNo - 1) * pageSize + index + 1 }}
      </span>
      <!-- <span slot="cs_bossname" slot-scope="text, record">
        {{ text.cs_bossname }}
      </span> -->
      <span slot="出勤工地数量" class="TitleFlex"
        ><div>出勤工地数量</div>
        <div class="Title_icon">
          <p
            class="Title_up"
            @click="sortColumn(2, 'w_a_wsnumBlue')"
            :class="w_a_wsnumBlue == true ? 'BlueBlock' : ''"
          >
            <a-icon type="caret-up" />
          </p>
          <p
            class="Title_down"
            @click="sortColumn(1, 'w_a_wsnumDownBlue')"
            :class="w_a_wsnumDownBlue == true ? 'BlueBlock' : ''"
          >
            <a-icon type="caret-down" />
          </p>
        </div>
      </span>
      <span slot="w_a_timesum" slot-scope="text">
        {{ $toHHmmss(text * 1000) }}
      </span>
      <span slot="action" slot-scope="text, record">
        <a @click="JumpWorkAttend(record)">查看详情</a>
      </span>
    </s-table>
    <div class="table_buttom_info">
      *按人员统计：根据查询条件，查看人员打卡工地数量统计
    </div>
    <a-modal
      class="tips-modal"
      title="提示"
      okText="确定"
      cancelText="取消"
      :visible="visibleExport"
      :confirm-loading="confirmExportLoading"
      @ok="exportOk"
      @cancel="exportCancel"
    >
      <p>确定导出？</p>
    </a-modal>
  </div>
</template>

<script>
import { SSearch, STable, ColumnsSet } from "@/components";
import { getAcountworker } from "@/api/dataStatistics.js";
import { ExportAcountworker } from "@/api/export";
import moment from "moment";
// 搜索条件
const queryItems = [
  { label: "姓名", value: "w_name", type: "input", defaultValue: "" },
  {
    label: "岗位",
    value: "w_post",
    type: "select",
    selectOption: "WorkPostWorkAttent",
  },
  {
    label: "分公司名称",
    value: "bc_id",
    type: "select",
    selectOption: "CompanyList",
    defaultValue: "",
  },
  { label: "工种", value: "w_type", type: "select", selectOption: "WorkType" },
  // 分公司名称
  {
    label: "考勤日期查询",
    value: "a_time",
    type: "dateRange",
    range: ["w_time_begin", "w_time_end"],
    keyType: "WorkerAttend",
    defaultValue: [],
  },
];
const columns = [
  {
    title: "序号",
    scopedSlots: { customRender: "serial" },
    width: "70px",
    fixed: "left",
    dataIndex: "serial",
    align: "center",
  },
  {
    title: "姓名",
    dataIndex: "w_name",
    // sorter: (a, b) => a.age - b.age,
    // scopedSlots: { customRender: "name" },
    align: "center",
  },
  {
    title: "分公司",
    dataIndex: "w_bc_name",
    align: "center",
    // sorter: (a, b) => a.age - b.age,
    // scopedSlots: { customRender: "name" },
  },

  {
    title: "岗位/工种",
    dataIndex: "w_post",
    // sorter: (a, b) => a.age - b.age,
    // scopedSlots: { customRender: "name" },
    align: "center",
  },
  {
    // title: "出勤工地数量",
    dataIndex: "w_a_wsnum",
    slots: { title: "出勤工地数量" },
    // scopedSlots: { customRender: "cs_bossname" },
    align: "center",
  },
  {
    title: "出勤总时长",
    dataIndex: "w_a_timesum",
    scopedSlots: { customRender: "w_a_timesum" },
    align: "center",
  },
  {
    title: "出勤明细",
    dataIndex: "action",
    width: "160px",
    scopedSlots: { customRender: "action" },
    align: "center",
  },
];

const orderParam = [
  "w_name",
  "bc_id",
  "w_post",
  "w_type",
  "w_time_begin",
  "w_time_end",
  "sort_rule",
  "pageNo",
  "pageSize",
];
export default {
  name: "People",
  components: {
    SSearch,
    STable,
    ColumnsSet,
  },
  data() {
    this.queryItems = queryItems;
    this.columns = columns;
    return {
      tableColumns: [],
      // 查询参数
      queryParam: {},
      // 导出弹出框
      visibleExport: false,
      confirmExportLoading: false,
      //当前页数，当前条数
      pageNo: 1,
      pageSize: 10,
      w_a_wsnumBlue: false,
      w_a_wsnumDownBlue: false,
      datepickerType: "date",
      start_time:
        new Date(new Date().setHours(0, 0, 0, 0)).getTime() / 1000 - 3600 * 24,
      end_time:
        new Date(new Date().setHours(0, 0, 0, 0)).getTime() / 1000 - 3600 * 24,
      // show_start_time: new Date(new Date()).getTime() / 1000 - 3600 * 24,
      // show_end_time: new Date(new Date()).getTime() / 1000 - 3600 * 24,
      // table_info: "统计截止昨天数据",
      table_info: "",
      isShowBtns: [],
    };
  },
  created() {
    this.tableColumns = columns;
    // this.changeColumns();
    let yestoday =
      new Date(new Date().setHours(0, 0, 0, 0)).getTime() - 1000 * 3600 * 24;
    this.queryItems[4].defaultValue = [moment(yestoday), moment(yestoday)];
    this.queryParam.w_time_begin = yestoday / 1000;
    this.queryParam.w_time_end = yestoday / 1000;
  },
  watch: {},
  mounted() {
    this.isShowBtns = sessionStorage.getItem("isShowBtns").split(",");
  },
  computed: {},
  methods: {
    // 自定义表头列
    // changeColumns() {
    //   let ColumnsSet = JSON.parse(
    //     localStorage.getItem("OrganizationalStatisticsColumnsSet")
    //   );
    //   let value = ColumnsSet ? ColumnsSet : [];
    //   let selectColumns = columns.filter((el) => value.includes(el.dataIndex));
    //   this.tableColumns = selectColumns.length ? selectColumns : columns;
    // },
    // 加载数据方法 必须为 Promise 对象
    loadData(parameter) {
      this.pageNo = parameter.pageNo;
      this.pageSize = parameter.pageSize;
      const param = Object.assign({}, parameter, this.queryParam);
      const requestParameters = this.$order(param, orderParam);
      const params = {
        ...requestParameters,
        verify: this.$verify(requestParameters, orderParam),
      };
      return getAcountworker(params).then((res) => {
        // this.data = res.data;
        res.data.pageNo = res.data.page;
        res.data.totalCount = res.data.sum;
        return res.data;
      });
    },

    // 导出
    handleExport() {
      this.visibleExport = true;
    },

    exportOk() {
      this.confirmExportLoading = true;
      const params = {
        w_name: this.queryParam.w_name,
        w_time_begin: this.queryParam.w_time_begin,
        w_time_end: this.queryParam.w_time_end,
        w_post: this.queryParam.w_post,
        w_type: this.queryParam.w_type,
        sort_rule: this.queryParam.sort_rule,
        bc_id: this.queryParam.bc_id,
      };
      ExportAcountworker(params).then((res) => {
        var blob = res;
        var reader = new FileReader();
        reader.readAsDataURL(blob); // 转换为base64，可以直接放入a标签href
        reader.onload = function (e) {
          // 转换完成，创建一个a标签用于下载
          var a = document.createElement("a");
          a.download = "考勤统计-按人员统计.xls";
          a.href = e.target.result;
          // $("body").append(a);    // 修复firefox中无法触发click
          a.click();
          a.remove();
        };

        this.visibleExport = false;
        this.confirmExportLoading = false;
      });
    },

    exportCancel() {
      this.visibleExport = false;
    },

    //查询触发
    fetchUserAttend(value) {
      this.queryParam = value;
      this.queryParam.w_time_begin = this.start_time;
      this.queryParam.w_time_end = this.end_time;
      this.show_start_time = this.start_time;
      this.show_end_time = this.end_time;
      console.log(this.$Format(this.queryParam.w_time_begin));
      this.$refs.table.refresh(true);
      if (this.datepickerType == "week") {
        // this.table_info = "统计截止上一周数据";
      } else if (this.datepickerType == "month") {
        // this.table_info = "统计截止上一月数据";
      } else {
        // this.table_info = "统计截止昨日数据";
      }
    },
    // 排序
    sortColumn(sort_rule, type) {
      console.log(sort_rule, type);
      this.queryParam.sort_rule = sort_rule;
      // this.loadData();
      this.$refs.table.refresh(true);

      if (type) {
        this.w_a_wsnumBlue = false;
        this.w_a_wsnumDownBlue = false;
        this[type] = true;
        console.log(type);
      }
    },

    GetMode(value) {
      console.log(value);
      this.datepickerType = value;
      let today = new Date(new Date().setHours(0, 0, 0, 0)).getTime() / 1000;
      if (value == "week") {
        this.start_time = this.$beginOfWeek(today * 1000);
        this.end_time = this.$endOfWeek(today * 1000);
      } else if (value == "month") {
        this.start_time = this.$beginOfMonth(today * 1000);
        this.end_time = this.$endOfMonth(today * 1000);
      } else {
        this.start_time = today - 3600 * 24;
        this.end_time = today - 3600 * 24;
      }

      // console.log(" this.start_time", this.$Format(this.start_time));
      // console.log(this.$Format(this.show_start_time));
    },
    // 跳转到人员考勤页
    JumpWorkAttend(record) {
      // localStorage.setItem("path", "WorkerAttend");
      // const { href } = this.$router.resolve({
      //   path: "/WorkerAttend",
      //   query: {
      //     start_time: this.start_time,
      //     end_time: this.end_time,
      //     w_name: record.w_name,
      //     // attendance_type: attendance_type,
      //   },
      // });
      // window.open(href, "_blank");
      this.$store.commit("SET_CURRENT", "WorkerAttend");
      this.$router.push({
        path: "/WorkerAttend",
        query: {
          start_time: this.start_time,
          end_time: this.end_time,
          w_name: record.w_name,
          // attendance_type: attendance_type,
        },
      });
    },
    getDatesStart(dates) {
      if (this.datepickerType == "week") {
        this.start_time = this.$beginOfWeek(dates * 1000);
      } else if (this.datepickerType == "month") {
        this.start_time = this.$beginOfMonth(dates * 1000);
      } else {
        this.start_time = dates;
        this.show_start_time = dates;
      }
      // console.log(this.$Format(dates));
    },
    getDatesEnd(dates) {
      if (this.datepickerType == "week") {
        this.end_time = this.$endOfWeek(dates * 1000);
      } else if (this.datepickerType == "month") {
        this.end_time = this.$endOfMonth(dates * 1000);
      } else {
        this.end_time = dates;
      }
      // console.log(this.$Format(dates));
    },
  },
};
</script>
<style lang="less" scoped>
// 表头居中显示
/deep/.ant-table-thead > tr > th {
  text-align: center;
}
.table_top {
  display: flex;
  justify-content: space-between;
  .table_info {
    color: gray;
  }
}
.table_buttom_info {
  text-align: left;
  color: gray;
}
.TitleFlex {
  display: flex;
  // line-height: 100%;
  align-items: center;
  .Title_icon {
    margin-left: 10px;
    // margin-top: -10px;
    padding-top: 10px;
  }
  .Title_up {
    // margin-bottom: -15px;
    margin-bottom: 0%;
    // background-color: pink;
    width: 15px;
    height: 10px;
    line-height: 10px;
  }
  .Title_down {
    // margin-top: -15px;
    width: 15px;
    height: 10px;
    line-height: 10px;
    // background-color: blue;
    // :hover {
    //   // color: aqua;
    // }
  }
  .BlueBlock {
    color: rgb(3, 104, 255);
  }
}
</style>
